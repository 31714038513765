<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row less-wide">
      <div class="col-sm-12">
        <div class="content-wrapper">
          <h2 class="text-center title d-block">User Terms</h2>
          <div class="content-box">
            <div class="heading">
              <h3>Terms and Conditions</h3>
              <span>Last updated: 1<sup>st</sup> December 2018</span>
            </div>
            <div class="desp">
              <p>
                THIS FOLLOWING USER AGREEMENT DESCRIBES THE TERMS AND CONDITIONS ON WHICH TREPR,
                LTD. OFFERS YOU ACCESS TO THE TREPR PLATFORM.
              </p>

              <p>
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION
                REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS
                LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF
                DISPUTES, AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS. IN
                PARTICULAR, TRAVELERS SHOULD UNDERSTAND HOW THE LAWS WORK IN THEIR RESPECTIVE
                COUNTRIES AND JURISDICTION. TRAVELERS SHOULD REVIEW LOCAL LAWS BEFORE LISTING THEIR
                TRAVEL SERVICE INTEREST IN TREPR.
              </p>

              <h5>1. KEY TERMS</h5>

              <p>
                "User" means a person who completes Trepr's account registration process, including
                but not limited to Travelers and Seekers, as described under "Account Registration"
                below.
              </p>

              <p>
                "Seeker" means a user who requests People, Package and/or Product services from
                Traveler via the Site, Application or Services, or a user who consumes such services
                by Listing his/her Travel service needs.
              </p>

              <p>
                "Traveler" means a user who creates People, Package and/or Product services Listing
                via the Site, Application and Services, or a User who provides such Travel services.
              </p>

              <p>
                "Listing" means Travel services that are listed by a Traveler/Seeker via the Site,
                Application, and Services.
              </p>

              <p>
                "Travel Service" means the collective term of People, Package and Product services.
              </p>

              <p>
                "User Content" means all content that a User posts, uploads, publishes, submits,
                transmits, or includes in their Listing or User profile to be made available through
                the Site, Application or Services.
              </p>

              <p>
                "Trepr Content" means all Content that Trepr makes available through the Site,
                Application, or Services, including any Content licensed from a third party, but
                excluding User Content.
              </p>

              <p>"Collective Content" means User Content and Trepr Content.</p>

              <p>
                "Content" means text, graphics, images, music, software (excluding the Application),
                audio, video, information or other materials.
              </p>

              <p>
                "Listing" means Travel services that are listed by a Traveler/Seeker via the Site,
                Application, and Services.
              </p>

              <p>
                "People Service" means a service for persons who want to travel different places in
                the world if someone accompanies them. Some of the common reasons are they travel
                for the first time, can't go to a new place without assistance, not aware of travel
                formalities/procedure, unfamiliar about travel etiquettes/culture of new place,
                uneasiness/not accustomed to multiple transits in between source and destination,
                find hard to handle luggage alone and/or simply can't speak the local language or
                converse with authorities if issue arises at the time of travel. This service helps
                people to travel anywhere accompanied by a traveler, who travels on that route and
                wishes to accompany people, rather than every time accompanied by their loved ones
                or beseech known people for travel needs.
              </p>

              <p>
                "Package Service" means a service for packages that need to be transported to
                different places in the world only through postal, mail, courier or taken with
                person, who possess them, with exorbitant cost spent. People don't always prefer to
                send much needed, but low priced, items through postal for a charge more than the
                actual item's worth. This service helps packages delivered by a traveler, who
                travels on same route and wishes to carry package, rather than every time packages
                wait for its possessor or seek known person to carry and to reach their destination.
              </p>

              <p>
                "Product Service" means a service for people who want to purchase products that are
                available in other countries and transported to different places in the world only
                through postal, courier or mail with exorbitant cost spent and undergoes enormous
                delay to reach destination due to custom &amp; excise checks at imported country.
                Thus, people don't always prefer to buy much needed products through international
                online websites. Instead, this service helps people to get their products purchased
                in a remote location by a traveler, packed &amp; carried in travel and delivered at
                the destination rather than people travel to the other places spending their time,
                effort and cost.
              </p>

              <p>
                "Passenger" means persons who are transported/accompanied by Traveler from origin to
                destination as part of people service.
              </p>

              <p>
                "Package" or "Baggage" or "Goods" or "Items" or "Commodity" means things which are
                carried, transported and delivered by traveler from origin to destination as part of
                package service.
              </p>

              <p>
                "Products" means things which are purchased, carried, transported and delivered by
                traveler from origin to destination as part of product service.
              </p>

              <p>
                "Concierge" or "Tasks" or "Activities" means work that is performed by Traveler as
                part of product service.
              </p>

              <p>
                "Service Request Period" means the time period starting from the time when a Travel
                service is requested by a Seeker/Traveler, within which a Seeker/Traveler may decide
                whether to confirm or reject that request, as stated on the Site, Application or
                Services. Different Travel service request periods may apply in different places (as
                determined by Trepr in its sole discretion).
              </p>

              <p>
                "Escrow Payment Service" means a contractual arrangement in which Trepr (third
                party) receives and disburses money or documents for the primary transacting parties
                (Seeker and Trepr), with the disbursement dependent on conditions agreed to by the
                transacting parties, or an account established by a Trepr for holding funds on
                behalf of the Trepr's principal until consummation or termination of a transaction;
                or, a trust account held in the Seeker's name to pay obligations.
              </p>

              <p>
                "Tax" or "Taxes" mean any sales taxes, value added taxes (VAT), goods and service
                taxes (GST), and fees that services provider/Trepr may be required by law to collect
                and remit to governmental agencies, and other similar municipal, state, federal and
                national indirect or other withholding and personal or corporate income taxes.
              </p>

              <p>
                Certain areas of the Site and Application (and your access to or use of certain
                aspects of the Services or Collective Content) may have different terms and
                conditions posted or may require you to agree with and accept additional terms and
                conditions. If there is a conflict between these terms and conditions posted for a
                specific area of the Site, Application, Services, or Collective Content, the latter
                terms and conditions will take precedence with respect to your use of or access to
                that area of the Site, Application, Services, or Collective Content.
              </p>

              <p>
                YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE, APPLICATION OR
                SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ON THE SITE, VIA THE
                APPLICATION OR THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, AND THAT
                YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS, WHETHER OR NOT YOU HAVE
                REGISTERED WITH THE SITE AND APPLICATION. IF YOU DO NOT AGREE TO THESE TERMS, THEN
                YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE, APPLICATION, SERVICES, OR COLLECTIVE
                CONTENT.
              </p>

              <h5>2. CONTRACTUAL RELATIONSHIP</h5>

              <p>
                These terms of Travel service constitute a legally binding agreement (the
                "Agreement") between you ("you," or "your") and Trepr, Ltd. ("Trepr," "we," "us" or
                "our"), a UK corporation, governing your use of the Trepr application, website, and
                technology platform (collectively, the "Trepr Platform").
              </p>

              <p>
                The Trepr Platform provides a marketplace in which persons who seek Travel services
                such as people, package and product ("Seekers") can be matched with persons who are
                interested to serve those Travel services ("Travelers"). Seekers and Travelers are
                collectively referred to herein as "Users" and each User shall create a User account
                that enables such User to access the Trepr Platform. For purposes of this Agreement,
                the services provided by Travelers to Seekers that are matched through the Platform
                shall be referred to collectively as the "Travel Services".
              </p>

              <p>
                YOU UNDERSTAND AND AGREE THAT TREPR IS NOT A PARTY TO ANY AGREEMENTS ENTERED INTO
                BETWEEN TRAVELERS OR SEEKERS, NOR IS TREPR A TRANSPORTATION AGENT, TRANSPORT SERVICE
                PROVIDER, COURIER COMPANY, POSTAL AGENT, GOODS TRANSISTOR OF ANY TYPE, TRANSPORTER,
                RECEIVER OF GOODS, SENDER OF GOODS, INTERMEDIATE STORER OF GOODS, PERSONAL
                ASSISTANT, LABOR, WORK CONTRACTOR, CONCIERGE SERVICE PROVIDER, AGENT OR INSURER.
                ALSO TERPR HAS NO CONTROL OVER THE CONDUCT OF TRAVELER, SEEKER, SENDER OR RECEIVER
                AND OTHER USERS OF THIS WEBSITE, APPLICATION AND SERVICES OR ANY TRANSACTIONS, AND
                DISCLAIMS ALL LIABILITY IN THIS REGARD. IT IS UP TO THE TRAVELER TO DECIDE WHETHER
                OR NOT TO OFFER A TRAVEL SERVICE TO A SEEKER CONTACTED THROUGH THE TREPR PLATFORM,
                AND IT IS UP TO THE SEEKER TO DECIDE WHETHER OR NOT TO ACCEPT A TRAVEL SERVICE FROM
                ANY TRAVELER CONTACTED THROUGH THE TREPR PLATFORM. ANY DECISION BY AN USER TO OFFER
                OR ACCEPT TRAVEL SERVICES ONCE SUCH USER IS MATCHED THROUGH THE TREPR PLATFORM IS A
                DECISION MADE IN SUCH USER'S SOLE DISCRETION. EACH TRAVEL SERVICE PROVIDED BY A
                TRAVELER TO A SEEKER MAY CONSTITUTE A SEPARATE AGREEMENT BETWEEN SUCH PERSONS.
              </p>

              <h5>3. MODIFICATION TO THE AGREEMENT</h5>

              <p>
                We reserve the right to modify the terms and conditions of this Agreement at any
                time, effective upon posting the amended terms on this site. If we make changes, we
                may notify you by, at a minimum, revising the "Last Updated" date at the top of this
                Agreement. You are responsible for regularly reviewing this Agreement. Continued use
                of the Trepr Platform or Services after any such changes shall constitute your
                consent to such changes. If you do not agree to any such changes you may not use or
                access the Trepr Platform or the Services.
              </p>

              <p>
                These terms expressly supersede prior agreements or arrangements with you. Trepr may
                immediately terminate these Terms or any Services with respect to you, or generally
                cease offering or deny access to the Travel services or any portion thereof, at any
                time for any reason. Supplemental terms may apply to certain Services, such as
                policies for a particular event, activity or promotion, and such supplemental terms
                will be disclosed to you in connection with the applicable Service(s). Supplemental
                terms are in addition to, and shall be deemed a part of, the Terms for the purposes
                of the applicable Service(s). Supplemental terms shall prevail over these Terms in
                the event of a conflict with respect to the applicable Services.
              </p>

              <p>
                Trepr may amend the Terms related to the Travel services from time to time.
                Amendments will be effective upon Trepr's posting of such updated Terms at this
                location or the amended policies or supplemental terms on the applicable Service(s).
                Your continued access or use of the Travel services after such posting constitutes
                your consent to be bound by the Terms, as amended. Our collection and use of
                personal information in connection with the Travel services is as provided in
                Trepr's Privacy Policy.
              </p>

              <h5>4. ELIGIBILITY</h5>

              <p>
                The Trepr Platform is available only to, and may only be used by individuals who can
                form legally binding contracts under applicable law. Without limiting the foregoing,
                the Trepr Platform is not available to children (persons under the age of 18) or
                Users who have had their User account temporarily or permanently deactivated. The
                Site, Application and Services are intended solely for persons who are 18 or older.
                Any access to or use of the Site, Application or Services by anyone under 18 is
                expressly prohibited. By accessing or using the Site, Application or Services you
                represent and warrant that you are 18 or older and that you have the right,
                authority and capacity to enter into and abide by the terms and conditions of this
                Agreement.
              </p>

              <h5>5. HOW THE SITE, APPLICATION AND SERVICES WORK</h5>

              <p>
                The Site, Application and Services can be used to facilitate the listing of travel
                services and transportation services of flight such as status, booking, notification
                and cancellation. You may view Listings as an unregistered visitor to the Site,
                Application and Services; however, if you wish to make a Travel service request or
                create a Listing, you must first register to create a Trepr Account (defined below).
              </p>

              <p>
                As stated above, Trepr makes available an online platform or marketplace with
                related technology for Seekers and Travelers to meet online and arrange for travel
                service requests directly with each other. Trepr is not an owner or provider or
                operator of transport, postal or concierge services including, but not limited to
                Flight, Cruise, Train, Bus, Car, Containers, or travel agent or ticketing agency or
                transportation authority or individual contractor. Unless explicitly specified
                otherwise in the Trepr platform, Trepr's responsibilities are limited to: (i)
                facilitating the availability of the Travelers for Travel, Package &amp; Concierge
                Services and Application and (ii) serving as an intermediary or limited payment
                collection agent of each Traveler for the purpose of accepting payments from Seekers
                on behalf of Travelers for Travel service rendered.
              </p>

              <p>
                You understand and agree that you are solely responsible for compliance with any and
                all laws, rules, regulations, and Tax obligations that may apply to your use of the
                Website, Application, Services and Content. In connection with your use of our
                Website, Application and Services, you may not and you agree that you will not:
              </p>

              <p>
                violate any local, state, provincial, national, travel, transaction, carrying,
                ferrying, couriering, inter-border law or other law or regulation, or any order of a
                court, including, without limitation, zoning restrictions and Tax regulations;
              </p>

              <p>
                use manual or automated software, devices, scripts robots, other means or processes
                to access, 'scrape," 'crawl' or "spider" any web pages or other services contained
                in the Website, Application, Services or Content;
              </p>

              <p>
                use the Website, Application or Services for any commercial or other purposes that
                are not expressly permitted by these Terms;
              </p>

              <p>
                copy, store or otherwise access any information contained on the Website,
                Application, Services or Content for purposes not expressly permitted by these
                Terms;
              </p>

              <p>
                infringe the rights of any person or entity, including without limitation, their
                intellectual property, privacy, publicity or contractual rights;
              </p>

              <p>
                interfere with or damage our Website, Application or Services, including, without
                limitation, through the use of viruses, cancel bots, Trojan horses, harmful code,
                flood pings, denial of -service attacks, packet or IP spoofing, forged routing or
                electronic mail address information or similar methods or technology;
              </p>

              <p>
                use our Website, Application or Services to transmit, distribute, post or submit any
                information concerning any other person or entity, including without limitation,
                photographs of others without their permission, personal contact information or
                credit, debit, calling card or account numbers;
              </p>

              <p>
                use our Website, Application or Services in connection with the distribution of
                unsolicited commercial email ('sparn') or advertisements unrelated to lodging in a
                private residence;
              </p>

              <p>
                'stalk' or harass any other user of our Website, Application, or Services or collect
                or store any personally identifiable information about any other user other than for
                purposes of transacting as an Trepr Traveler or Sender;
              </p>

              <p>
                Offer, as a Traveler or Seeker, any transactions that you do not yourself own or
                have permission to.
              </p>

              <p>
                register for more than one Trepr Account or register for an Trepr Account on behalf
                of an individual other than yourself;
              </p>

              <p>
                contact users for any purpose other than asking a question related to travel
                services;
              </p>

              <p>
                recruit or otherwise solicit any Traveler or Seeker or other User to join
                Third-Party services or Websites that are competitive to Trepr, without Trepr's
                prior written approval;
              </p>

              <p>
                impersonate any person or entity, or falsify or otherwise misrepresent yourself or
                your affiliation with any person or entity;
              </p>

              <p>
                use automated scripts to collect information or otherwise interact with the Website,
                Application or Services;
              </p>

              <p>
                use the Website, Application and Services to find a Traveler or Seeker and then
                complete a booking of transaction independent of the Website, Application or
                Services;
              </p>

              <p>or post, upload, publish, submit or transmit any Content that:</p>

              <p>
                <span class="indent">
                  a. infringes, misappropriates or violates a third party's patent, copyright,
                  trademark, trade secret, moral rights or other intellectual property rights, or
                  rights of publicity or privacy;
                </span>
                <span class="indent">
                  b. violates, or encourages any conduct that would violate, any applicable law or
                  regulation or would give rise to civil liability;
                </span>
                <span class="indent"> c. is fraudulent, false, misleading or deceptive; </span>
                <span class="indent">
                  d. is defamatory, obscene, pornographic, vulgar or offensive;
                </span>
                <span class="indent">
                  e. promotes discrimination, bigotry, racism, hatred, harassment or harm against
                  any individual or group;
                </span>
                <span class="indent">
                  f. is violent or threatening or promotes violence or actions that are threatening
                  to any other person; or
                </span>
                <span class="indent">
                  g. promotes illegal or harmful activities or substances;
                </span>
              </p>

              <p>
                systematically retrieve data or other content from our Website, Application or
                Services to create or compile, directly or indirectly, in single or multiple
                downloads,
              </p>

              <p>
                a collection, compilation, database, directory or the like, whether by manual
                methods, through the use of bots, crawlers, or spiders, or otherwise;
              </p>

              <p>
                use, display, mirror or frame the Website or Application, or any individual element
                within the Website, Services, or Application, Trepr's name, any Trepr trademark,
                logo or other proprietary information, or the layout and design of any page or form
                contained on a page, without Trepr's express written consent;
              </p>

              <p>
                access, tamper with, or use non-public areas of the Website or Application, Trepr's
                computer systems, or the technical delivery systems of Trepr's providers;
              </p>

              <p>
                attempt to probe, scan, or test the vulnerability of any Trepr system or network or
                breach any security or authentication measures;
              </p>

              <p>
                avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any
                technological measure implemented by Trepr or any of Trepr's providers or any other
                Third-Party (including another user) to protect the Website, Services, Application
                or Collective Content;
              </p>

              <p>
                forge any TCP/IP packet header or any part of the header information in any email or
                newsgroup posting, or in any way use the Website, Services, Application or
                Collective Content to send altered, deceptive or false source identifying
                information;
              </p>

              <p>
                attempt to decipher, decompile, disassemble or reverse engineer any of the software
                used to provide the Website, Services, Application or Collective Content; or
                advocate, encourage, or assist any Third-Party in doing any of the foregoing.
              </p>

              <p>
                Trepr will have the right to investigate and prosecute violations of any of the
                above to the fullest extent of the law. Trepr may involve and cooperate with law
                enforcement authorities in prosecuting users who violate these Terms. You
                acknowledge that Trepr has no obligation to monitor your access to or use of the
                Website, Application, Services or Collective Content or to review or edit any User
                Content, but has the right to do so for the purpose of operating the Website,
              </p>

              <p>
                Application and Services, to ensure your compliance with these Terms, or to comply
                with applicable law or the order or requirement of a court, administrative agency or
                other governmental body. Trepr reserves the right, at any time and without prior
                notice, to remove or disable access to any Collective Content that Trepr, at its
                sole discretion, considers to be objectionable for any reason, in violation of these
                Terms or otherwise harmful to the Website, Application or Services.
              </p>

              <p>
                PLEASE NOTE THAT, AS STATED ABOVE, THE SITE, APPLICATION AND SERVICES ARE INTENDED
                TO BE USED TO FACILITATE TRAVELERS AND SEEKERS CONNECTING AND RENDERING TRAVEL
                SERVICES DIRECTLY WITH EACH OTHER. TREPR CANNOT AND DOES NOT CONTROL THE CONTENT
                CONTAINED IN ANY LISTINGS AND THE CONDITION, LEGALITY OR SUITABILITY OF ANY TRAVEL
                SERVICES. TREPR IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED
                TO ANY AND ALL LISTINGS AND TRAVEL SERVICES. ACCORDINGLY, ANY TRAVEL SERVICE
                REQUESTS WILL BE MADE OR ACCEPTED AT THE USER'S OWN RISK.
              </p>

              <h5>ACCOUNT REGISTRATION</h5>

              <p>
                In order to access certain features of the Site and Application, and to request a
                Travel service or create a Listing, you must register to create an account ("Trepr
                Account") and become a User. You may register to join the Services directly via the
                Site or Application or as described in this section.
              </p>

              <p>
                You can also register to join by logging into your account with certain third-party
                social networking sites ("SNS") (including, but not limited to, Facebook; each such
                account, a "Third-Party Account"), via our Site or Application, as described below.
                As part of the functionality of the Site, Application and Services, you may link
                your Trepr Account with Third-Party Accounts, by either: (i) providing your
                Third-Party Account login information to Trepr through the Site, Services or
                Application; or (ii) allowing Trepr to access your Third-Party Account, as permitted
                under the applicable terms and conditions that govern your use of each Third-Party
                Account. You represent that you are entitled to disclose your Third-Party Account
                login information to Trepr and/or grant Trepr access to your Third-Party Account
                (including, but not limited to, for use for the purposes described herein), without
                breach by you of any of the terms and conditions that govern your use of the
                applicable Third-Party Account and without obligating Trepr to pay any fees or
                making Trepr subject to any usage limitations imposed by such third-party service
                providers. By granting Trepr access to any Third-Party Accounts, you understand that
                Trepr will access, make available and store (if applicable) any Content that you
                have provided to and stored in your Third-Party Account ("SNS Content") so that it
                is available on and through the Site, Services and Application via your Trepr
                Account and Trepr Account profile page. Unless otherwise specified in these Terms,
                all SNS Content, if any, will be considered to be User Content for all purposes of
                these Terms. Depending on the Third-Party Accounts you choose and subject to the
                privacy settings that you have set in such Third-Party Accounts, personally
                identifiable information that you post to your Third-Party Accounts will be
                available on and through your Trepr Account on the Site, Services and Application.
                Please note that if a Third-Party Account or associated service becomes unavailable
                or Trepr's access to such Third-Party Account is terminated by the third-party
                service provider, then SNS Content will no longer be available on and through the
                Site, Services and Application.
              </p>

              <p>
                PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
                WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                THIRD-PARTY SERVICE PROVIDERS. Trepr makes no effort to review any SNS Content for
                any purpose, including but not limited to for accuracy, legality or non-infringement
                and Trepr is not responsible for any SNS Content.
              </p>

              <p>
                Your Trepr Account and Account profile page will be created for your use of the Site
                and Application based upon the personal information you provide to us or that we
                obtain via an SNS as described above. You may not have more than one (1) active
                Trepr Account. You agree to provide true, accurate, current and complete information
                during the registration process and to update such information to keep it accurate,
                current and complete. Trepr reserves the right to suspend or terminate your Trepr
                Account and your access to the Site, Application and Services if you create more
                than one (1) Trepr Account, or if any information provided during the registration
                process or thereafter proves to be inaccurate, fraudulent, not current or
                incomplete. You are responsible for safeguarding your password. You agree that you
                will not disclose your password to any Third-Party and that you will take sole
                responsibility for any activities or actions under your Trepr Account, whether or
                not you have authorized such activities or actions. You will immediately notify
                Trepr of any unauthorized use of your Trepr Account.
              </p>

              <h5>THE SERVICES</h5>

              <p>
                The Services constitute a technology platform that enables users of Trepr's mobile
                applications or websites provided as part of the Services (each, an "Application")
                to arrange and schedule travel and/or logistics services with Travelers who are
                interested to serve, including independent Third-Party transportation providers and
                Third-Party logistics providers under agreement with Trepr or certain of Trepr's
                affiliates ("Third-Party Providers"). Unless otherwise agreed by Trepr in a separate
                written agreement with you, the Travel services are made available solely for your
                personal, non commercial use. YOU ACKNOWLEDGE THAT TREPR DOES NOT PROVIDE
                TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER,
                PACKAGE OR GOODS CARRIER, AND CONCIERGE.
              </p>

              <h5>LICENSE</h5>

              <p>
                Subject to your compliance with these Terms, Trepr grants you a limited,
                non-exclusive, non-sublicensable, revocable, non-transferable license to: (i) access
                and use the Applications on your personal device solely in connection with your use
                of the Travel services; and (ii) access and use any content, information and related
                materials that may be made available through the Travel services, in each case
                solely for your personal, non commercial use. Any rights not expressly granted
                herein are reserved by Trepr and Trepr's licensors.
              </p>

              <h5>RESTRICTIONS</h5>

              <p>
                With respect to your use of the Trepr Platform and your participation in the
                Services, you agree that you will not:
              </p>
              <span class="indent"> a. impersonate any person or entity; </span>

              <span class="indent">
                b. stalk, threaten, or otherwise harass any person, or carry any weapons or
                hazardous material or life-threatening tools;
              </span>

              <span class="indent"> c. violate any law, statute, ordinance or regulation; </span>

              <span class="indent">
                d. interfere with or disrupt the Services or the Trepr Platform or the servers or
                networks connected to the Trepr Platform;
              </span>
              <span class="indent">
                e. post information or interact on the Trepr Platform or Services in a manner which
                is false, inaccurate, misleading (directly or by omission or failure to update
                information), defamatory, libelous, abusive, obscene, profane, offensive, sexually
                oriented, threatening, harassing, or illegal;
              </span>
              <span class="indent">
                f. use the Trepr Platform in any way that infringes any third party's rights,
                including but not limited to: intellectual property rights, copyright, patent,
                trademark, trade secret or other proprietary rights or rights of publicity or
                privacy;
              </span>
              <span class="indent">
                g. attempt to gain unauthorized access to or impair any aspect of the Services or
                its related systems or networks.
              </span>
              <span class="indent">
                h. post, email or otherwise transmit any malicious code, files or programs designed
                to interrupt, damage, destroy or limit the functionality of any computer software or
                hardware or telecommunications equipment or surreptitiously intercept or expropriate
                any system, data or personal information;
              </span>
              <span class="indent">
                i. forge headers or otherwise manipulate identifiers in order to disguise the origin
                of any information transmitted through the Trepr Platform;
              </span>
              <span class="indent">
                j. "frame" or "mirror" any part of the Trepr Platform, without our prior written
                authorization or use meta tags or code or other devices containing any reference to
                us in order to direct any person to any other website for any purpose; or
              </span>
              <span class="indent">
                k. modify, adapt, translate, reverse engineer, decipher, decompile, reverse engineer
                or otherwise disassemble any portion of the Trepr Platform or any software used on
                or for the Trepr Platform;
              </span>
              <span class="indent">
                l. reproduce, prepare derivative works based upon, distribute, license, lease, sell,
                resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or
                otherwise exploit the Services except as expressly permitted by Trepr;
              </span>
              <span class="indent">
                m. cause or launch any programs or scripts for the purpose of scraping, indexing,
                surveying, or otherwise data mining any portion of the Services or unduly burdening
                or hindering the operation and/or functionality of any aspect of the Services;
              </span>
              <span class="indent">
                n. rent, lease, lend, sell, redistribute, license or sublicense the Trepr Platform
                or access to any portion of the Trepr Platform;
              </span>
              <span class="indent">
                o. use any robot, spider, site search/retrieval application, or other manual or
                automatic device or process to retrieve, index, scrape, "data mine", or in any way
                reproduce or circumvent the navigational structure or presentation of the Trepr
                Platform or its contents;
              </span>
              <span class="indent">
                p. create liability for us or cause us to become subject to regulation as a
                transportation carrier or postal service provider or concierge service provider;
              </span>
              <span class="indent"> q. link directly or indirectly to any other websites; </span>
              <span class="indent">
                r. transfer or sell your User account, password and/or identification to any other
                party; or
              </span>
              <span class="indent">
                s. cause any Third-Party to engage in the restricted activities above.
              </span>
              <span class="indent">
                t. register for more than one Trepr Account or register for an Trepr Account on
                behalf of an individual other than yourself;
              </span>
              <span class="indent">
                u. offer, as a Seeker, any Travel service that you do not yourself do or delegate it
                to others (without limiting the foregoing, you will not list Travel services as a
                Traveler if you are serving in the capacity of a service agent or listing agent for
                a third party);
              </span>
              <span class="indent">
                v. unless Trepr explicitly permits otherwise, request a Travel service that is not
                for you or that is not for people you explicitly called out at the time of making
                Travel service request.
              </span>
              <span class="indent">
                w. contact a Traveler for any purpose other than asking a question related to a
                Travel service or Listings;
              </span>
              <span class="indent">
                x. contact a Seeker for any purpose other than asking a question related to a Travel
                service or Listings or such Seeker's use of the Site, Application and Services;
              </span>
              <span class="indent">
                y. recruit or otherwise solicit any Traveler or other User to join third-party
                services or websites that are competitive to Trepr, without Trepr's prior written
                approval;
              </span>
              <span class="indent">
                z. as a Traveler, submit any Listing with false or misleading Travel service
                information, or submit any Listing that you do not intend to honor;
              </span>

              <p>
                We reserve the right, but we have no obligation, to suspend or deactivate your User
                account if you do not comply with these prohibitions.
              </p>

              <h5>6. THIRD-PARTY SERVICES AND CONTENT</h5>

              <p>
                The Services may be made available or accessed in connection with Third-Party
                services and content (including advertising) that Trepr does not control. You
                acknowledge that different terms of use and privacy policies may apply to your use
                of such third-party services and content. Trepr does not endorse such third-party
                services and content and in no event shall Trepr be responsible or liable for any
                products or services of such Third-Party providers. Additionally, including but not
                limited to, Apple Inc., or Google, Inc. will be a third-party beneficiary to this
                contract if you access the Services using Applications developed for Apple iOS or
                Android powered mobile devices, respectively. These third-party beneficiaries are
                not parties to this contract and are not responsible for the provision or support of
                the Services in any manner. Your access to the Travel services using these devices
                is subject to terms set forth in the applicable third-party beneficiary's terms of
                service.
              </p>

              <h5>7. PROPRIETARY RIGHTS NOTICES</h5>

              <p>
                All trademarks, service marks, logos, trade names and any other proprietary
                designations of Trepr used herein are trademarks or registered trademarks of Trepr.
                Any other trademarks, service marks, logos, trade names and any other proprietary
                designations are the trademarks or registered trademarks of their respective
                parties.
              </p>

              <h5>8. FEEDBACK</h5>

              <p>
                We welcome and encourage you to provide feedback, comments and suggestions for
                improvements to the Site, Application and Services ("Feedback"). You may submit
                Feedback by emailing us, through the "Contact" section of the Site and Application,
                or by other means of communication. You acknowledge and agree that all Feedback you
                give us will be the sole and exclusive property of Trepr and you hereby irrevocably
                assign to Trepr and agree to irrevocably assign to Trepr all of your right, title,
                and interest in and to all Feedback, including without limitation all worldwide
                patent, copyright, trade secret, moral and other proprietary or intellectual
                property rights therein, and waive any moral rights you may have in such Feedback.
                At Trepr's request and expense, you will execute documents and take such further
                acts as Trepr may reasonably request to assist Trepr to acquire, perfect, and
                maintain its intellectual property rights and other legal protections for the
                Feedback.
              </p>

              <h5>9. COPYRIGHT POLICY</h5>

              <p>
                Trepr respects copyright law and expects its users to do the same. It is Trepr's
                policy to terminate in appropriate circumstances the Trepr Accounts of Users or
                other account holders who repeatedly infringe or are believed to be repeatedly
                infringing the rights of copyright holders. Please refer "Copyright Policy" section
                to know more details.
              </p>

              <h5>10. TREPR COMMUNICATIONS</h5>

              <p>
                By becoming a User, you expressly consent and agree to accept and receive
                communications from us, including via e-mail, text message, calls, and push
                notifications to the cellular telephone number you provided to us. By consenting to
                being contacted by Trepr, you understand and agree that you may receive
                communications generated by automatic telephone dialing systems and/or which will
                deliver pre recorded messages sent by or on behalf of Trepr, its affiliated
                companies and/or Travelers, including but not limited to: operational communications
                concerning your User account or use of the Trepr Platform or Services, updates
                concerning new and existing features on the Trepr Platform, communications
                concerning promotions run by us or our Third-Party partners, and news concerning
                Trepr and industry developments. IF YOU WISH TO OPT-OUT OF PROMOTIONAL EMAILS, TEXT
                MESSAGES, OR OTHER COMMUNICATIONS, YOU MAY OPT-OUT BY FOLLOWING THE UNSUBSCRIBE
                OPTIONS PROVIDED TO YOU. Standard text messaging charges applied by your cell phone
                carrier will apply to text messages we send. You acknowledge that you are not
                required to consent to receive promotional messages as a condition of using the
                Trepr Platform or the Services. However, you acknowledge that opting out of
                receiving text messages or other communications may impact your use of the Trepr
                Platform or the Services.
              </p>

              <h5>11. PROMOTIONAL CODES</h5>

              <p>
                Trepr may, in Trepr's sole discretion, create promotional codes that may be redeemed
                for Account credit, or other features or benefits related to the Services and/or a
                Third-Party Provider's services, subject to any additional terms that Trepr
                establishes on a per promotional code basis ("Promo Codes"). You agree that Promo
                Codes: (i) must be used for the intended audience and purpose, and in a lawful
                manner; (ii) may not be duplicated, sold or transferred in any manner, or made
                available to the general public (whether posted to a public form or otherwise),
                unless expressly permitted by Trepr; (iii) may be disabled by Trepr at any time for
                any reason without liability to Trepr; (iv) may only be used pursuant to the
                specific terms that Trepr establishes for such Promo Code; (v) are not valid for
                cash; and (vi) may expire prior to your use. Trepr reserves the right to withhold or
                deduct credits or other features or benefits obtained through the use of Promo Codes
                by you or any other user in the event that Trepr determines or believes that the use
                or redemption of the Promo Code was in error, fraudulent, illegal, or in violation
                of the applicable Promo Code terms or these Terms.
              </p>

              <h5>12. USER PROVIDED CONTENT</h5>

              <p>
                Trepr may, in Trepr's sole discretion, permit you from time to time to submit,
                upload, publish or otherwise make available to Trepr through the Services textual,
                audio, and/or visual content and information, including commentary and feedback
                related to the Services, initiation of support requests, and submission of entries
                for competitions and promotions ("User Content"). Any User Content provided by you
                remains your property. However, by providing User Content to Trepr, you grant Trepr
                a worldwide, perpetual, irrevocable, transferable, royalty-free license, with the
                right to sublicense, to use, copy, modify, create derivative works of, distribute,
                publicly display, publicly perform, and otherwise exploit in any manner such User
                Content in all formats and distribution channels now known or hereafter devised
                (including in connection with the Services and Trepr's business and on third-party
                sites and services), without further notice to or consent from you, and without the
                requirement of payment to you or any other person or entity.
              </p>

              <p>
                You represent and warrant that: (i) you either are the sole and exclusive owner of
                all User Content or you have all rights, licenses, consents and releases necessary
                to grant Trepr the license to the User Content as set forth above; and (ii) neither
                the User Content nor your submission, uploading, publishing or otherwise making
                available of such User Content nor Trepr's use of the User Content as permitted
                herein will infringe, misappropriate or violate a third party's intellectual
                property or proprietary rights, or rights of publicity or privacy, or result in the
                violation of any applicable law or regulation.
              </p>

              <p>
                You agree to not provide User Content that is defamatory, libelous, hateful,
                violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by
                Trepr in its sole discretion, whether or not such material may be protected by law.
                Trepr may, but shall not be obligated to, review, monitor, or remove User Content,
                at Trepr's sole discretion and at any time and for any reason, without notice to
                you.
              </p>

              <h5>13. NETWORK ACCESS AND DEVICES</h5>

              <p>
                You are responsible for obtaining the data network access necessary to use the
                Services. Your mobile network's data and messaging rates and fees may apply if you
                access or use the Services from a wireless-enabled device. You are responsible for
                acquiring and updating compatible hardware or devices necessary to access and use
                the Services and Applications and any updates thereto. Trepr does not guarantee that
                the Services, or any portion thereof, will function on any particular hardware or
                devices. In addition, the Services may be subject to malfunctions and delays
                inherent in the use of the Internet and electronic communications.
              </p>

              <h5>14. PAYMENT</h5>

              <p>
                You understand that use of the Services may result in charges to you for the
                services or goods you receive from a Traveler/Third-Party Provider ("Charges").
                After you have received services or goods obtained through your use of the Service,
                Trepr will facilitate your payment of the applicable Charges on behalf of the
                Third-Party Provider, as such Traveler/Third-Party Provider's limited payment
                collection agent. Payment of the Charges in such manner shall be considered the same
                as payment made directly by you to the Traveler/Third-Party Provider. Charges will
                be inclusive of applicable taxes where required by law. Charges paid by you are
                final and non-refundable, unless otherwise determined by Trepr. You retain the right
                to request lower Charges from a Traveler/Third-Party Provider for services or goods
                received by you from such Traveler/Third-Party Provider at the time you receive such
                services or goods. Trepr will respond accordingly to any request from a Third-Party
                Provider to modify the Charges for a particular service or good.
              </p>

              <p>
                All Charges are due immediately and payment will be facilitated by Trepr using the
                preferred payment method designated in your Account, after which Trepr will send you
                a receipt by email. If your primary Account payment method is determined to be
                expired, invalid or otherwise not able to be charged, you agree that Trepr may, as
                the Third-Party Provider's limited payment collection agent, use a secondary payment
                method in your Account, if available.
              </p>

              <p>
                As between you and Trepr, Trepr reserves the right to establish, remove and/or
                revise Charges for any or all services or goods obtained through the use of the
                Services at any time in Trepr's sole discretion. Further, you acknowledge and agree
                that Charges applicable in certain geographical areas may increase substantially
                during times of high demand. Trepr will use reasonable efforts to inform you of
                Charges that may apply, provided that you will be responsible for Charges incurred
                under your Account regardless of your awareness of such Charges or the amounts
                thereof. Trepr may from time to time provide certain users with promotional offers
                and discounts that may result in different amounts charged for the same or similar
                services or goods obtained through the use of the Services, and you agree that such
                promotional offers and discounts, unless also made available to you, shall have no
                bearing on your use of the Services or the Charges applied to you.
              </p>

              <p>
                This payment structure is intended to fully compensate the Third-Party Provider for
                the services or goods provided. Except with respect to Travel services requested
                through the Application, Trepr does not designate any portion of your payment as a
                tip or gratuity to the Traveler/Third-Party Provider. Any representation by Trepr
                (on Trepr's website, in the Application, or in Trepr's marketing materials) to the
                effect that tipping is "voluntary," "not required," and/or "included" in the
                payments you make for services or goods provided is not intended to suggest that
                Trepr provides any additional amounts, beyond those described above, to the
                Third-Party Provider. You understand and agree that, while you are free to provide
                additional payment as a gratuity to any Third-Party Provider who provides you with
                services or goods obtained through the Service, you are under no obligation to do
                so. Gratuities are voluntary. After you have received services or goods obtained
                through the Service, you will have the opportunity to rate your experience and leave
                additional feedback about your Traveler/Third-Party Provider, and Traveler will have
                the opportunity to rate you.
              </p>

              <p>
                As a Seeker, you agree that any amounts charged following a service (a "Charge") are
                mandatory and due and payable immediately upon completion of the service. You as a
                Traveler, authorize Trepr to charge you an amount (as determined by Trepr in its
                sole discretion) for any loss/damages/harm made to Seeker or goods or related
                services even without your consent. Trepr has the authority and reserves the right
                to determine and modify pricing by posting applicable Charges to your market's Trepr
                Countries or Cities page. You are responsible for reviewing the applicable Trepr
                Cities page and shall be responsible for all Charges incurred under your User
                account regardless of your awareness of such Charges or the amounts thereof. In all
                markets, Charges are not subject to minimum or maximum limits.
              </p>

              <span class="indent">
                a. Service Fees. Fees consist of a base price and incremental charges based on the
                duration and distance of Traveler's travel/effort to fulfill the request. For
                particularly short distances, minimum Services Fees may apply. Service Fees may be
                subject to a multiplier at times of high demand period ("High Demand Time") as
                determined by Trepr. We will use reasonable efforts to inform you of any High Demand
                Time multipliers in effect at the time you request a Travel service.
              </span>
              <span class="indent">
                b. Trust &amp; Safety Fee. Trepr may assess a per-service "Trust &amp; Safety Fee"
                to offset Trepr's expenses related to promoting trust and safety on the Trepr
                platform. Such expenses are dynamic and may or may not exceed Trepr's Trust &amp;
                Safety Fee collections. Excess Trust &amp; Safety Fee collections, if any, will be
                deemed general revenue.
              </span>
              <span class="indent" id="cancellation_process"> c. Cancellation Fees </span>
              <span class="indent nested">
                1. Cancellation policies are the conditions under which Trepr will return money to a
                seeker upon the seeker cancelling the travel service.
              </span>
              <span class="indent nested">
                2. Cancellation policies protect travelers in the event of unexpected seeker
                cancellations. Each listing on Trepr has a cancellation policy determined by Trepr.
                We strongly recommend reviewing all cancellation policies before making travel
                services.
              </span>
              <span class="indent nested">
                3. You must agree to the Trepr's cancellation policy when submitting a travel
                service request.
              </span>
              <span class="indent nested">
                4. If you already have an accepted travel service, you can find the cancellation
                policy on your itinerary. If you need to cancel your travel service, you'll be
                refunded according to Trepr's cancellation policy. If your traveler needs to cancel
                your travel service, you can choose to rebook with another traveler or to be fully
                refunded.
              </span>
              <span class="indent nested">
                5. If you, as a Seeker, make a request but cancel it anytime 1 week before
                Traveler's travel or start of travel service, then you may not be charged and the
                amount withheld will be transferred back to your account within a commercially
                reasonable time.
              </span>
              <span class="indent nested">
                6. If you, as a Seeker, make a request but cancel it within a week and before a day
                of Traveler's travel or start of travel service, then you may be charged only 10% of
                travel service fee and remaining withheld amount will be transferred back to your
                account within a commercially reasonable time, and the current travel service
                request will be void/cancelled.
              </span>
              <span class="indent nested">
                7. If you, as a Seeker, make a request but cancel it within a day of Traveler's
                travel or start of travel service, then you may forfeit 50% of travel service fee
                and remaining withheld amount will be transferred back to your account, and the
                current travel service request will be void/cancelled.
              </span>
              <span class="indent nested">
                8. If you, as a Seeker, make a request but cancel it after start of travel service,
                then you may forfeit the full travel service fee and no withheld amount will be
                transferred back to your account, and the current travel service request will be
                void/cancelled.
              </span>
              <span class="indent nested">
                9. In the event that you, as a Seeker, cancel travel service request on the Trepr
                Platform repeatedly or frequently, or fail to support Traveler for your travel
                service in a timely manner, you agree to pay a full "Cancellation Fee". We may also
                assess a Cancellation Fee against you if we suspect that you have intentionally
                cancelled travel services to disrupt the Trepr Platform or the provision of
                Services, and it is possible that Trepr will cancel your profile temporarily or
                permanently.
              </span>
              <span class="indent nested">
                10. If a Traveler accepts your travel service request but cancels later 1 week
                before start of travel service, Trepr will notify you about the changes, provide
                alternative Travelers for your travel service and refund entire amount withheld back
                to your account, within a commercially reasonable time, and the current travel
                service booking will be void/cancelled.
              </span>
              <span class="indent nested">
                11. If a Traveler accepts your travel service request but cancels later within 1
                week before start of travel service, Trepr will notify you about the changes
                immediately and recommend alternative Travelers for your travel service with highest
                priority. If you pick another Traveler for the same travel service and make a
                request, then your contract continues else the entire amount withheld will be
                transferred back to your account, within a commercially reasonable time, and the
                current travel service request will be void/cancelled.
              </span>
              <span class="indent nested">
                12. Due to any last minute changes or ticket cancellation, if Traveler accepts your
                travel service request but doesn't show up on the travel day or at the time of start
                of travel service, please let us know through Trepr application or call us directly
                so that we will find immediate alternative for same travel service with highest
                priority, provide coupons 15 - 25 % service fee for next booking and transfer the
                entire amount withheld to your account, within a commercially reasonable time and
                the current travel service request will be void/cancelled.
              </span>
              <span class="indent nested">
                13. In the case of People service, if Traveler doesn't show up at the start of
                travel service and you make reservation or book ticket in a transport based on
                Traveler's itinerary, then Trepr will not be responsible or will not reimburse for
                the ticket booked by you because travel arrangements must be coordinated between
                seeker and traveler and passenger can still continue the travel with the help of
                another Traveler or with airlines assistance.
              </span>
              <span class="indent nested">
                14. In the case of Package service, if package is not delivered at the destination
                because goods that are carried violate any countries/state/province policy or seized
                by authorities, then Trepr will not reimburse for packages or held accountable. It
                is your responsibility to ensure any items/goods in the package do not violate
                policy or restricted by any countries/state/province/authorities in any place
                traveler's travel. In this scenario, we will obtain seizure/violation notice/letter
                provided by respective authorities from traveler and help you in formalities to get
                them back from authorities.
              </span>
              <span class="indent nested">
                15. In the case of Package service, if package is not delivered at the destination
                even though it clears all travel formalities, please let us know through Trepr
                application or call us directly so that we will transfer the entire service charges
                withheld back to your account, within a commercially reasonable time, and Traveler
                will be charged a percentage % or full fee or flat amount, for loss/damage/harm
                caused to property, same amount will be credit to seeker as a compensation for the
                loss/damage/harm incurred (NOTE: the charges or flat amount will be determined by
                Trepr based on the travel service requested).
              </span>
              <span class="indent nested">
                16. In the case of Product service, if concierge services are not started on time
                and delayed without prior notice or concurrence by seeker, please let us know
                through Trepr application or call us directly so that we will transfer the entire
                service charges withheld back to your account, within a commercially reasonable
                time, and Traveler will be charged a percentage % or full fee or flat amount, for
                loss/damage/harm caused to property, same amount will be credit to seeker as a
                compensation for the loss/damage/harm incurred (NOTE: the charges or flat amount
                will be determined by Trepr based on the travel service requested).
              </span>
              <span class="indent nested">
                17. In the event that Traveler cancels Travel service requests on the Trepr Platform
                repeatedly or frequently, or fails to support Seeker for any Travel service in a
                timely manner, Traveler agrees to pay a full "Cancellation Fee". We may also assess
                a Cancellation Fee against Travelers if we suspect that they have intentionally
                cancelled Travel services to disrupt the Trepr Platform or the provision of
                Services.
              </span>
              <span class="indent nested">
                18. If you, as a Traveler, cancel a confirmed Travel service, you agree that Trepr
                may apply penalties or consequences to you or your Listing, including (i) publishing
                an automated review on your Listing indicating that a Travel service reservation was
                cancelled, (ii) keeping the calendar for your Listing unavailable or blocked for the
                dates of the cancelled Travel services, or (iii) imposing a cancellation fee (to be
                withheld from your future payouts or charged to the credit card on file in your
                Trepr Account). You will be notified of the situations in which a cancellation fee
                applies before you decide to cancel.
              </span>
              <span class="indent nested">
                19. In the event of last minute cancellation or inappropriate conduct at the time of
                Travel service such as failing to accompany people of confirmed reservations,
                deliver the package, in same condition, at the destination, product (all tasks) not
                completed as promised, without mutual consent from Seeker, then Traveler agrees to
                pay part/flat or full "Cancellation Fee". In such cases, decisions made by Trepr is
                final and Traveler agrees to abide by cancellation policies defined by Trepr.
              </span>
              <span class="indent nested">
                20. You agree that Trepr has no liability to pay/reimburse/compensate any user for
                any, including but not limited to, cancellations/ losses/damages or harm caused to
                life and property of Seekers or Travelers. However, Trepr makes all possible
                attempts to settle dispute between two parties if such cases arise. Hereby, you are
                indicating that you have read, and that you understand and agree to be bound by
                these cancellation/refund terms.
              </span>
              <span class="indent"> d. Damage to items/goods/package/products </span>
              <span class="indent">
                As a Traveler, you are responsible for carrying the item/goods/package in condition
                as asked by Seeker in good faith without using, damaging, stealing, breaking or
                doing any type of harm to item/goods/package, and Trepr is not liable for any
                damages for any loss or harm to items/goods/package. Both Travelers and Sender agree
                to cooperate with and assist Trepr in good faith, and to provide Trepr with such
                information and take such actions as may be reasonably requested by Trepr, in
                connection with any complaints or disputes or claims made by users relating to
                transactions or with respect to any investigation undertaken by Trepr or a
                representative of Trepr regarding use or abuse of the Website, Application or the
                Services.
              </span>
              <span class="indent">
                e. Damage Fee. If the package is delivered not in same condition or any damage
                (except minor/reasonable) happens, then Traveler agrees to pay a "Damage Fee" of up
                to total cost of package(s), as mentioned in the listing by seeker, carried
                depending on the extent of the damage (as determined by Trepr in its sole
                discretion), towards replacing the items or fixing. Thus, every traveler is
                requested to verify and confirm fair price of the goods quoted in the listing before
                and after accepting service request but before start of service. Trepr reserves the
                right (but is not obligated) to verify or otherwise require documentation of damages
                prior to processing the Damage Fee.
              </span>
              <span class="indent">
                f. Other Charges. Other fees or charges that may arise to complete product services,
                shall be handled by Escrow payment service. Therefore, once Traveler finishes
                Seeker's concierge services all the withheld Seeker's money will be transferred to
                Traveler. Any surcharges may apply to your service, including: actual or anticipated
                airport fees, state or local fees, and processing fees for split payments. In
                addition, where required by law Trepr will collect applicable taxes. Any tips will
                be provided entirely to the applicable Traveler.
              </span>
              <span class="indent">
                g. Facilitation of Payments. All Charges are facilitated through a third-party
                payment processing service (e.g., Stripe, Inc., or Braintree, a division of PayPal,
                Inc.). Trepr may replace its third-party payment processing services without
                providing notice to you. Charges shall only be made through the Trepr Platform. Cash
                payments are strictly prohibited.
              </span>
              <span class="indent">
                h. No Refunds. Charges are assessed immediately following completion of the service
                to your authorized payment method. All Charges are non-refundable. This no-refund
                policy shall apply at all times regardless of your decision to terminate usage of
                the Trepr Platform, any disruption to the Trepr Platform or Services, or any other
                reason whatsoever.
              </span>
              <span class="indent">
                i. As a Traveler, you will receive applicable Service Fees (net of Trepr's
                Administrative Fee, as discussed below) and any tips provided by Seekers to you for
                the Travel services you provide. Trepr will process all payments due to you through
                its Third-Party payments processor. You acknowledge and agree that such amounts
                shall not include any interest and will be net of any amounts that we are required
                to withhold by law. You expressly authorize Trepr to set the prices on your behalf
                for all Charges that apply to the provision of Travel services. Trepr reserves the
                right to withhold all or a portion of service fees if it believes that you have
                attempted to defraud or abuse Trepr or Trepr's payment systems.
              </span>
              <span class="indent">
                j. In exchange for permitting you to offer your Travel services through the Trepr
                Platform and marketplace as a server, you agree to pay Trepr (and permit Trepr to
                retain) a fee of up to 25% (the "Administrative Fee") of the Travel service fees
                paid by Seekers for such Services. In certain jurisdictions, including but not
                limited to London or New York, you agree to pay Trepr an Administrative Fee of up to
                35% of service fees to cover additional operating costs. The amount of the
                applicable Administrative Fee will be communicated to you via email but is subject
                to change on a prospective basis at Trepr's discretion.
              </span>
              <span class="indent">
                k. Trepr, at its sole discretion, may make available promotions with different
                features to any Traveler or prospective Travelers. These promotions, unless made to
                you, shall have no bearing whatsoever on your Agreement or relationship with Trepr.
              </span>

              <h5>15. NO ENDORSEMENT</h5>

              <p>
                Trepr does not endorse any User or any Travel services. You understand that Travel
                service Descriptions or Verified Images are intended only to indicate a photographic
                representation of the credibility of Users and their Travel services at the time the
                images were submitted, which may change from time to time. Travel service
                Descriptions or Verified Images are therefore not an endorsement by Trepr of any
                Travel services or any User. Users are required by these Terms to provide accurate
                information, and although Trepr may undertake additional checks and processes
                designed to help verify or check the identities or backgrounds of seekers or
                Travelers, we do not make any representations about, confirm, or endorse any User or
                the User's purported identity or background.
              </p>

              <p>
                Any references in the Site, Application or Services to a User being "verified" or
                "connected" (or similar language) only indicate that the User has completed a
                relevant verification process, and does not represent anything else. Any such
                description is not an endorsement, certification or guarantee by Trepr about any
                User, including of the User's identity and whether the User is trustworthy, safe or
                suitable. Instead, any such description is intended to be useful information for you
                to evaluate when you make your own decisions about the identity and suitability of
                others whom you contact or interact with via the Site, Application and Services. We
                therefore recommend that you always exercise due diligence and care when deciding
                whether to provide Travel service or to accept a Travel service request from
                Traveler, or to have any other interaction with any other User.
              </p>

              <p>
                By using the Site, Application or Services, you agree that any legal remedy or
                liability that you seek to obtain for actions or omissions of other Users or other
                third parties will be limited to a claim against the particular Users or other third
                parties who caused you harm. You agree not to attempt to impose liability on or seek
                any legal remedy from Trepr with respect to such actions or omissions. Accordingly,
                we encourage you to communicate directly with other Users on the Site and Services
                regarding any Travel service requests or Listings made by you. This limitation shall
                not apply to any claim by a Traveler against Trepr regarding the remittance of
                payments received from a Seeker by Trepr on behalf of a Traveler, which instead
                shall be subject to the limitations described in the section below entitled
                "Limitation of Liability".
              </p>

              <h5>16. SERVICE REQUESTS AND FINANCIAL TERMS FOR USERS</h5>

              <p>
                Using Trepr Platform, one user can request for a Travel service and other user can
                make the decision. If you are a Traveler and a Travel service is requested via the
                Site, Application or Services, then you will be required to either confirm or reject
                the request within the Travel service Request Period, otherwise the Travel service
                request will automatically expire. When a Travel service is requested via the Site,
                Application or Services, we will share with you (i) the first and last name of the
                Seeker who has requested the Travel service, (ii) a link to the Seeker's Trepr
                Account profile page, (iii) if the Seeker and Traveler have both connected their
                Trepr accounts to SNS, the names of any users of an SNS with whom you are "friends"
                or associated on the SNS if such individuals are also "friends" or associated with
                the Seeker on such SNS, and (iv) an indication of whether or not the Seeker has
                provided other information to Trepr, such as a verified email address, connection to
                SNSs, or a government ID. If you are unable to confirm or decide to reject a Travel
                service request within the Travel service Request Period, any amounts collected by
                Trepr for the requested Travel service will be refunded to the applicable Seeker's
                credit card and any pre-authorization of such credit card will be released. When you
                confirm a Travel service requested by a Seeker, Trepr will send you an email, text
                message or message via the Application confirming such Travel service, depending on
                the selections you make via the Site, Application and Services.
              </p>

              <p>
                If you are a Seeker and you receive a response for Travel service need you posted
                via the Site, Application or Services, then you will be required to either confirm
                or reject the request within the Travel service Response Period, otherwise the
                Travel service request will automatically expire. When a Travel service is requested
                via the Site, Application or Services, we will share with you (i) the first and last
                name of the Traveler who has requested the Travel service, (ii) a link to the
                Traveler's Trepr Account profile page, (iii) if the Seeker and Traveler have both
                connected their Trepr accounts to SNS, the names of any users of an SNS with whom
                you are "friends" or associated on the SNS if such individuals are also "friends" or
                associated with the Traveler on such SNS, and (iv) an indication of whether or not
                the Traveler has provided other information to Trepr, such as a verified email
                address, connection to SNSs, or a government ID. When you confirm a Travel service
                responded by a Traveler, Trepr will send you an email, text message or message via
                the Application confirming such Travel service, depending on the selections you make
                via the Site, Application and Services.
              </p>

              <p>
                Trepr Payments will collect the Total Fees at the time of Travel service
                confirmation (i.e. when the Traveler confirms the Travel service request) and will
                initiate payment of the Service Fees (less Trepr's Traveler Fees and any Taxes in
                respect of the Traveler Fees, such as VAT in Europe) to the Traveler any time after
                24 hours of completion of Travel service by Traveler. The time it takes for the
                Traveler to receive payouts may depend upon the payout method chosen by the
                Traveler. Some methods involve the use of third-party payment processors, who may
                impose their own additional charges for the use of their Travel services on the
                Travelers, including by deducting their charges from the payout amount.
              </p>

              <p>
                If you owe or agree to pay any amount via Trepr Payments to Trepr (whether as a
                result of your Travel services provided or actions as a Seeker or otherwise), then
                Trepr Payments may (but is not obliged to) withhold the amount owing to Trepr from
                any payout amounts due to you as a Traveler, and use the withheld amount to set off
                the amount owed by you to Trepr. If Trepr Payments does so, then your obligation to
                pay Trepr will be extinguished to the extent of the amount withheld by Trepr
                Payments, and Trepr will cease to owe to you any obligations (including, but not
                limited to, any obligation to pay you) with respect to the amount withheld. In
                addition to the amount due, if your account is delinquent or you otherwise have
                chargebacks on your account, you may be charged fees that are incidental to our
                collection of these delinquent amounts and chargebacks. Such fees or charges may
                include collection fees, convenience fees, or other Third-Party charges. You hereby
                explicitly agree that all communication in relation to delinquent accounts will be
                made by electronic mail or by phone, as provided to Trepr by you. Such communication
                may be made by Trepr or by anyone on its behalf, including but not limited to a
                Third-Party collection agent.
              </p>

              <h5>
                17. APPOINTMENT OF TREPR PAYMENTS AS LIMITED PAYMENT COLLECTION AGENT FOR TRAVELER
              </h5>

              <p>
                Each Traveler hereby appoints Trepr Payments as the Traveler's limited payment
                collection agent solely for the purpose of accepting the Service Fees from Seekers.
              </p>

              <p>
                Each Traveler agrees that payment made by a Seeker through Trepr Payments, shall be
                considered the same as a payment made directly to the Traveler, and the Traveler
                will make the Travel service available to the Seeker in the agreed-upon manner as if
                the Traveler has received the Service Fees. Each Traveler agrees that Trepr may, in
                accordance with the cancellation policy selected by the Traveler and reflected in
                the relevant Listing, (i) permit the Seeker to cancel the Travel service and (ii)
                refund (via Trepr Payments) to the Seeker that portion of the Service Fees specified
                in the applicable cancellation policy. Each Traveler understands that as Trepr
                Payments accepts payments from Seekers as the Traveler's limited payment collection
                agent and that Trepr Payments' obligation to pay the Traveler is subject to and
                conditional upon successful receipt of the associated payments from Seekers. Trepr
                does not guarantee payments to Travelers for amounts that have not been successfully
                received by Trepr Payments from Seekers. In accepting appointment as the limited
                authorized agent of the Traveler, Trepr Payments assumes no liability for any acts
                or omissions of the Traveler.
              </p>

              <p>
                Please note that Trepr does not currently charge fees for the creation of Listings.
                However, you as a User acknowledge and agree that Trepr reserves the right, in its
                sole discretion, to charge you for and collect fees from you for the creation of
                Listings. Please note that Trepr will provide notice of any Listing fee collection
                via the Site, Application and Services, prior to implementing such a Listing fee
                feature.
              </p>

              <p>
                The Travelers, not Trepr, are solely responsible for honoring any confirmed Travel
                services and making available any Travel services requested through the Site,
                Application and Services. If you, as a Seeker, choose to enter into a transaction
                with a Traveler for the Travel service, you agree and understand that you will be
                required to enter into an agreement with the Traveler and you agree to accept any
                terms, conditions, rules and restrictions associated with such Travel service
                imposed by the Traveler. You acknowledge and agree that you, and not Trepr, will be
                responsible for performing the obligations of any such agreements, that Trepr is not
                a party to such agreements, and that, with the exception of its payment obligations
                hereunder, Trepr disclaims all liability arising from or related to any such
                agreements. You acknowledge and agree that, notwithstanding the fact that Trepr is
                not a party to the agreement between you and the Traveler, Trepr Payments acts as
                the Traveler's payment collection agent for the limited purpose of accepting
                payments from you on behalf of the Traveler. Upon your payment of the Total Fees to
                Trepr Payments, your payment obligation to the Traveler for the Service Fees is
                extinguished, and Trepr Payments is responsible for remitting the Service Fees (less
                the Traveler Fees and any Taxes in respect of the Traveler Fees, such as VAT in
                Europe), in the manner described in these Terms. In the event that Trepr Payments
                does not remit any such amounts as described in these Terms, such Traveler will have
                recourse only against such Trepr entity.
              </p>

              <p>
                The Total Fees payable will be displayed to a Seeker before the Seeker sends a
                Travel service request to a Traveler. As noted above, the Traveler is required to
                either confirm or reject the Travel service request within the Travel service
                Request Period; otherwise, the requested Travel service will be automatically
                cancelled. If a requested Travel service is cancelled (i.e. not confirmed by the
                applicable Traveler), any amounts collected by Trepr Payments will be refunded to
                such Seeker, depending on the selections the Seeker makes via the Site and
                Application, and any pre-authorization of such Seeker's credit card will be
                released, if applicable.
              </p>

              <p>
                You as a Seeker agree to pay Trepr for the Total Fees for any Travel service
                requested in connection with your Trepr Account if such requested Travel services
                are confirmed by the applicable Traveler. In order to establish a Travel service
                pending the applicable Traveler's confirmation of your requested Travel service, you
                understand and agree that Trepr Payments, on behalf of the Traveler, reserve the
                right, in its sole discretion, to (i) obtain a pre-authorization via your credit
                card for the Total Fees or (ii) charge your credit card a nominal amount, not to
                exceed one dollar (£1), or a similar sum in the currency in which you are
                transacting (e.g. one euro or one British pound), to verify your credit card. As a
                general rule, Trepr Payments will collect the Total Fees due once Trepr receives
                confirmation of your Travel service from the applicable Traveler; if necessary,
                Total Fees may instead be collected at a later point. Please note that Trepr cannot
                control any fees that may be charged to a Seeker by his or her bank related to
                Trepr's collection of the Total Fees, and Trepr disclaims all liability in this
                regard.
              </p>

              <p>
                In connection with your requested Travel service, you will be asked to provide
                customary billing information such as name, billing address and credit card
                information either to Trepr Payments or its third-party payment processor(s). You
                agree to pay Trepr Payments for any confirmed Travel services made in connection
                with your Trepr Account in accordance with these Terms by one of the methods
                described on the Site or Application, e.g. by PayPal or credit card. You hereby
                authorize the collection of such amounts by charging the credit card provided as
                part of requesting the Travel service, either directly by Trepr Payments or
                indirectly, via a third-party online payment processor or by one of the payment
                methods described on the Site or Application. You also authorize Trepr Payments to
                charge your credit card in the event of inappropriate conduct during the Travel
                service as contemplated earlier under "Cancellation Fee" Rules for Security
                Deposits, if applicable. If you are directed to Trepr Payments' third-party payment
                processor(s), you may be subject to terms and conditions governing use of that third
                party's service and that third party's personal information collection practices.
                Please review such terms and conditions and privacy policy before using the Travel
                services. Once your confirmed Travel service transaction is complete you will
                receive a confirmation email summarizing your confirmed Travel service.
              </p>

              <h5>18. SERVICE FEES</h5>

              <p>
                In consideration for the use of Trepr's online marketplace and platform, Trepr
                charges the Service Fees. Where applicable, Taxes (such as VAT in Europe) may also
                be charged in respect of the Seeker Fees. Seeker Fees are, as noted above, included
                in the Total Fees. Balances will be remitted by Trepr Payments to Travelers via
                PayPal, direct deposit or other payment methods, as described on the Site or via the
                Application, in the Traveler's currency of choice, depending upon the selections the
                Traveler makes via the Site, Application and Services. Amounts may be rounded up or
                down as described the "Rounding Off" section below.
              </p>

              <p>
                Please note that Trepr Payments, may impose or deduct foreign currency processing
                costs on or from any payments or payouts by Trepr in currencies other than GBP or
                U.S. dollars. More information on any such costs or deductions will be available via
                the Site and Application. Except as otherwise provided herein, Service Fees are
                non-refundable.
              </p>

              <h5>19. RECURRING PAYMENTS</h5>

              <p>
                In some instances, Seekers may be required to make recurring, incremental payments
                toward the Total Fees owed for a confirmed request before beginning the applicable
                Travel service (collectively, "Recurring Payments"). More information on Recurring
                Payments will be made available via the Site, Application and Services, if
                applicable. If Recurring Payments apply to a confirmed Request, then the Seeker
                authorizes Trepr Payments, on behalf of the Traveler, to collect the Total Fees and
                the Traveler agrees that such Trepr entity will initiate payouts to the Traveler, in
                the increments and at the frequency associated with the applicable Recurring
                Payments, each as identified on the Site, Application and Services.
              </p>

              <h5>20. ROUNDING OFF</h5>

              <p>
                Trepr may, in its sole discretion, round up or round down amounts that are payable
                from or to Seekers or Travelers to the nearest whole functional base unit in which
                the currency is denominated (e.g. to the nearest pound, dollar, euro or other
                supported currency); for example, Trepr will round up an amount of £101.50 to
                £102.00, and £101.49 to $£101.00.
              </p>

              <p>
                Some currencies are denominated in large numbers. In those cases, Trepr may
                determine the functional base unit in which those currencies are denominated to be
                10, 100 or 1,000 of the currency; the corresponding examples for such currencies
                would be for Trepr to round up an amount of 1,045 up to 1,050 and 1,044 down to
                1,040, or 35,450 up to 35,500 and 35,449 down to 35,400, or 837,500 up to 838,000
                and 837,499 down to 837,000.
              </p>

              <h5>21. PAYMENT PROCESSING ERRORS</h5>

              <p>
                We will take steps to rectify any payment processing errors that we become aware of.
                These steps may include crediting or debiting (as appropriate) the same payment
                method used for the original payout to or payment by you, so that you end up
                receiving or paying the correct amount.
              </p>

              <h5>22. DONATIONS</h5>

              <p>
                Some Travelers may pledge to donate a portion of the funds they receive from
                confirmed Requests made via the Site, Application and Services to a particular cause
                or charity. We do not take any responsibility or liability for whether the Traveler
                does in fact make the donation he or she pledged to make. In such cases, the
                Traveler in question is responsible for his or her own compliance with all laws and
                regulations applicable to such pledges and/or fund-raising.
              </p>

              <h5>23. TAXES</h5>

              <p>
                Tax regulations may require us to collect appropriate tax information from our
                Travelers, or to withhold taxes from payouts to Travelers, or both. For instance,
                HMRC regulations stipulate that we must collect a PAYE forms: P45, P60, P11D from UK
                Travelers, (IRS Form W-9 from US Travelers), and an appropriate IRS Form W-8 (e.g.
                Form W-8BEN) from non-US Travelers. You as a Traveler are solely responsible for
                keeping the information in your tax forms current, complete and accurate. If you as
                a Traveler fail to provide us with documentation that we determine to be sufficient
                to alleviate our obligation (if any) to withhold taxes from payments to you (e.g.
                where you are a US Traveler and you fail to provide US government with a completed
                IRS Form W-9), we reserve the right in our sole discretion to freeze all payouts to
                you until resolution, to withhold such amounts as required by law, or to do both.
              </p>

              <p>
                You as a Traveler understand and agree that you are solely responsible for
                determining (i) your applicable Tax reporting requirements, and (ii) the Taxes that
                should be included, and for including Taxes to be collected or obligations relating
                to applicable Taxes in Listings. You are also solely responsible for remitting to
                the relevant authority any Taxes included or received by you. Trepr cannot and does
                not offer Tax-related advice to any Users.
              </p>

              <p>
                Where applicable, or based upon request from a Traveler, Trepr may issue a valid VAT
                invoice to such Traveler. You understand and acknowledge that appropriate
                governmental agencies, departments or authorities (the "Tax Authority") where your
                Travel service begins or currency charged may require Taxes to be collected from
                Seekers or Travelers on the amount paid for the Travel services offered, and to be
                remitted to the respective Tax Authority. The laws in jurisdictions may vary, but
                these taxes may be required to be collected and remitted as a percentage of the
                service Fees set by Trepr, a set amount per day, or other variations, and are
                sometimes called "postal taxes," "carrier taxes," "transportation taxes," or "Value
                Added taxes," (hereafter, "Service Taxes").
              </p>

              <h5>24. ADDITIONAL SERVICE REQUEST WITHOUT THE TRAVELER'S CONSENT</h5>

              <p>
                Seekers agree that a confirmed reservation is merely a license granted by the
                Traveler to the Seeker to obtain Travel services for the number of requests made
                through the Site, Application or Services and in accordance with the User's
                agreement with the Traveler. If a Seeker request additional service, which are not
                listed by Traveler or offered without Trepr's agreement, then Seekers agree that the
                Trepr can charge the Seeker, for each additional service to cover the inconvenience
                suffered by the Traveler, plus all applicable Service Fees, Taxes. Seekers agree
                that Trepr Payments, in its role as limited collection agent for the Traveler, shall
                charge the Seeker's credit card or other payment methods it has on file to collect
                these additional fees. In addition, Trepr Payments may recover any costs and
                expenses it incurs in collecting the Additional fees by charging the Seeker's credit
                card or other payment method on file.
              </p>

              <h5>25. FOREIGN CURRENCY</h5>

              <p>
                Trepr's online platform facilitates requests between Seekers and Travelers who may
                prefer to pay and to receive payments in different currencies, which may require
                foreign currency conversions to accommodate these differing currency preferences.
                Although the Trepr platform allows seekers to view the price of Listings in a number
                of currencies, the currencies available for seekers to make and receive payments may
                be limited, and may not include the default currency in any given geographic
                location. Each foreign currency conversion is processed at a foreign currency
                conversion rate. This rate generally refers to the amount of one currency that must
                be paid to buy a certain amount of another currency at a given time. For example, if
                it costs US$125.00 to buy €100.00, the currency conversion rate of US dollars to
                Euros would be 1.25, and the currency conversion rate from Euros to US dollars would
                be 0.8. Currency conversion rates will vary from time to time.
              </p>

              <h5>KEY DEFINITIONS</h5>

              <p>
                "Display Currency" means the currency in which seekers view Listing prices on the
                Trepr platform. Seekers may choose and change the Display Currency in order to view
                the pricing for a Listing in a number of different supported currencies.
              </p>

              <p>
                "Request Currency" means the currency in which a Seeker has to pay for his or her
                Request. At the time the Seeker submits a Travel service request, the Trepr platform
                will select the Currency, based on the country of origin of Travel services and the
                payment methods available for that country. Trepr supports only a certain number of
                currencies. The Currency for a service may be different from the relevant Listing
                Currency.
              </p>

              <p>
                "Listing Currency" means the currency in which a Listing's price is set. The Listing
                Currency is set by the Trepr.
              </p>

              <p>
                "Payout Currency" means the currency in which a Traveler's payout will be paid to
                the Traveler. The Payout Currency is set by the Traveler.
              </p>

              <p>
                "Base Exchange Rate" means a system-wide rate used by Trepr for foreign currency
                conversion that is in effect at the time the foreign currency conversion is
                processed, and does not include any fee or mark-up by Trepr. Trepr establishes the
                Base Exchange Rate using data from one or more third parties such as PayPal
                (http://www.paypal.com).
              </p>

              <p>
                "Adjusted Exchange Rate" means a rate for foreign currency conversion that is
                calculated by adding a mark-up to the Base Exchange Rate. This mark-up represents a
                charge imposed by Trepr for its holding costs and foreign currency risks.
              </p>

              <h5>FOREIGN CURRENCY CONVERSIONS ON THE TREPR PLATFORM</h5>

              <p>
                Trepr Payments will process a foreign currency conversion in the following
                situations:
              </p>

              <span class="indent">
                a. The Display Currency is different from the Listing Currency when a seeker views a
                Listing: Trepr will calculate the estimated Total Fees in the Display Currency, by
                applying either the Base Exchange Rate or the Adjusted Exchange Rate at the time of
                the view to the estimated Total Fees in the Listing Currency. The Adjusted Exchange
                Rate will be applied if the Display Currency is a supported Request Currency, and it
                is different from the Listing Currency. Otherwise, the Base Exchange Rate will be
                applied.
              </span>
              <span class="indent">
                b. The Request Currency is different from the Listing Currency when a Seeker submits
                a Travel service request for a Listing: Trepr will calculate the Total Fees in the
                Request Currency, by applying either the Base Exchange Rate or the Adjusted Exchange
                Rate at the time of the Travel service request to the Total Fees in the Listing
                Currency. The Seeker will be able to view the actual exchange rate applied. The
                Seeker Fee, which is a percentage of the applicable Service Fees, will be calculated
                based on the Service Fees in the Request Currency (i.e. after conversion from the
                Listing Currency). The Adjusted Exchange Rate will be applied if the Display
                Currency is the same as the Request Currency, and it is different from the Listing
                Currency for the Listing. Otherwise, the Base Exchange Rate will be applied.
              </span>
              <span class="indent">
                c. The Payout Currency is different from the Listing Currency when Trepr initiates a
                payout: generally, Trepr will calculate the payout to the Traveler, by applying the
                Base Exchange Rate on the date that Trepr initiates the payout to the Service Fees
                (less Trepr's Traveler Fees and any Taxes in respect of the Traveler Fees, such as
                VAT in Europe) in the Listing Currency. The Traveler will be able to view the actual
                exchange rate applied in his or her transaction history in the Site or Application.
                In some cases where the payout method selected by the Traveler involves certain
                third-party payment processors (such as Western Union), (i) Trepr will send the
                payout amount to the processor in a major currency (e.g. GBP or US dollars), by
                applying the relevant Base Exchange Rate for the Listing Currency to that major
                currency, (ii) the Traveler will be able to view the payout amount sent by Trepr in
                that major currency in his or her transaction history in the Site or Application,
                and (iii) the processor will calculate the actual payout to the Traveler by applying
                its own foreign currency conversion rate for that major currency to the Payout
                Currency.
              </span>
              <span class="indent">
                d. When a confirmed Request is modified or cancelled, and there had been a foreign
                currency conversion when the Request was submitted: the foreign currency conversion
                for any additional payments required of the Seeker or any refund to the Seeker will
                be processed at the same rate as applied to the earlier payment by the Seeker.
              </span>

              <p>
                When you as a Seeker submit a Travel service request for a Listing, you will be able
                to view the actual exchange rate used to calculate the Total Fees in the Request
                Currency. Where the Adjusted Exchange Rate is applied, you will be able to view the
                markup included in the rate. The actual exchange rate (and any markup included in
                the rate) will also be stated in the billing receipt for your Request.
              </p>

              <p>
                Trepr updates the Base Exchange Rate on a regular basis, but not on a real-time
                basis. In particular, Trepr does not always change the Base Exchange Rate
                immediately when its costs of foreign exchange change. Accordingly, the Base
                Exchange Rate may not be identical to the applicable market rate in effect at the
                specific time a foreign currency conversion is processed.
              </p>

              <p>
                Please note that your payment company (for example, your credit or bank card issuer)
                will use a currency conversion rate for and may impose a currency conversion fee on
                your payment or payout, if your card or bank account is denominated in a currency
                that is different from the Request Currency or the Payout Currency respectively.
                Similarly, third-party payment processors may also use a currency conversion rate
                for or impose a currency conversion fee on your payment or payout. All of these
                currency conversion rates and fees are not controlled by or known to Trepr.
              </p>

              <h5>26. DISCLAIMERS</h5>

              <p>
                IF YOU CHOOSE TO USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT OR
                PARTICIPATE IN THE REFERRAL PROGRAM, YOU DO SO AT YOUR SOLE RISK. YOU ACKNOWLEDGE
                AND AGREE THAT TREPR DOES NOT HAVE AN OBLIGATION TO CONDUCT BACKGROUND OR REGISTERED
                SEX OFFENDER CHECKS ON ANY USER, INCLUDING, BUT NOT LIMITED TO, SEEKERS AND
                TRAVELERS, BUT MAY CONDUCT SUCH BACKGROUND OR REGISTERED SEX OFFENDER CHECKS IN ITS
                SOLE DISCRETION. THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT AND REFERRAL
                PROGRAM ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. WITHOUT LIMITING THE FOREGOING, TREPR EXPLICITLY DISCLAIMS ANY WARRANTIES
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR
                NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF
                TRADE. TREPR MAKES NO WARRANTY THAT THE SITE, APPLICATION, SERVICES, COLLECTIVE
                CONTENT, INCLUDING, BUT NOT LIMITED TO, THE LISTINGS OR ANY TRAVEL SERVICES, OR THE
                REFERRAL PROGRAM WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
                SECURE, OR ERROR-FREE BASIS. TREPR MAKES NO WARRANTY REGARDING THE QUALITY OF ANY
                LISTINGS, TRAVEL SERVICES, TRAVELERS, SEEKERS, YOUR ACCRUAL OF THE TRAVEL SERVICES
                OR COLLECTIVE CONTENT OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
                RELIABILITY OF ANY COLLECTIVE CONTENT OBTAINED THROUGH THE SITE, APPLICATION,
                SERVICES OR REFERRAL PROGRAM.
              </p>

              <p>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM TREPR OR THROUGH
                THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, WILL CREATE ANY WARRANTY NOT
                EXPRESSLY MADE HEREIN.
              </p>

              <p>
                YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH
                OTHER SEEKERS OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM
                YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION OR
                SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY TRAVELERS OR SEEKERS. YOU UNDERSTAND
                THAT TREPR DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SITE,
                APPLICATION OR SERVICES OR TO REVIEW OR VISIT ANY TRAVELERS OR SEEKERS FOR
                AUTHENTICATION OF INFORMATION. TREPR MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
                THE CONDUCT OF USERS OF THE SITE, APPLICATION OR SERVICES OR THEIR COMPATIBILITY
                WITH ANY CURRENT OR FUTURE USERS OF THE SITE, APPLICATION OR SERVICES. YOU AGREE TO
                TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS
                OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM YOU
                COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION OR
                SERVICES, INCLUDING, BUT NOT LIMITED TO, SEEKERS AND TRAVELERS, PARTICULARLY IF YOU
                DECIDE TO MEET OFFLINE OR IN PERSON REGARDLESS OF WHETHER SUCH MEETINGS ARE
                ORGANIZED BY TREPR. NOTWITHSTANDING TREPR'S APPOINTMENT AS THE LIMITED PAYMENT
                COLLECTION AGENT OF THE TRAVELERS FOR THE PURPOSE OF ACCEPTING PAYMENTS FROM SEEKERS
                ON BEHALF OF THE TRAVELERS, TREPR EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR
                OMISSION OF ANY USER OR OTHER THIRD PARTY.
              </p>

              <h5>27. LIMITATION OF LIABILITY</h5>

              <p>
                YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE
                RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, APPLICATION, SERVICES AND
                COLLECTIVE CONTENT, YOUR LISTING OR REQUEST OF ANY SERVICES VIA THE SITE,
                APPLICATION AND SERVICES, YOUR PARTICIPATION IN THE REFERRAL PROGRAM, AND ANY
                CONTACT YOU HAVE WITH OTHER USERS OF TREPR WHETHER IN PERSON OR ONLINE REMAINS WITH
                YOU. NEITHER TREPR NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
                DELIVERING THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT OR THE REFERRAL
                PROGRAM WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
                DAMAGES, INCLUDING LOST LIFE OF HUMAN/LIVING BEING, LOST PROFITS, LOSS OF DATA OR
                LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE
                COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY
                INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, FROM
                THE USE OF OR INABILITY TO USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE
                CONTENT, FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE
                SITE, APPLICATION, OR SERVICES OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR
                INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION, SERVICES, OR YOUR
                PARTICIPATION IN THE REFERRAL PROGRAM OR FROM YOUR LISTING OR REQUEST OF ANY TRAVEL
                SERVICES VIA THE SITE, APPLICATION AND SERVICES, WHETHER BASED ON WARRANTY,
                CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
                AND WHETHER OR NOT TREPR HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN
                IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
                PURPOSE.
              </p>

              <p>
                EXCEPT FOR OUR OBLIGATIONS TO PAY AMOUNTS TO APPLICABLE TRAVELERS PURSUANT TO THESE
                TERMS OR AN APPROVED PAYMENT REQUEST UNDER THE TREPR, IN NO EVENT WILL TREPR'S
                AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND YOUR USE OF
                THE SITE, APPLICATION AND SERVICES INCLUDING, BUT NOT LIMITED TO, FROM YOUR LISTING
                OR REQUEST OF ANY TRAVEL SERVICES VIA THE SITE, APPLICATION AND SERVICES, OR FROM
                THE USE OF OR INABILITY TO USE THE SITE, APPLICATION, SERVICES, OR COLLECTIVE
                CONTENT OR YOUR PARTICIPATION IN THE REFERRAL PROGRAM AND IN CONNECTION WITH ANY
                TRAVEL SERVICE OR INTERACTIONS WITH ANY OTHER USERS, EXCEED THE AMOUNTS YOU HAVE
                PAID OR OWE FOR REQUESTS VIA THE SITE, APPLICATION AND SERVICES AS A SEEKER IN THE
                TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, OR IF YOU
                ARE A TRAVELER, THE AMOUNTS PAID BY TREPR TO YOU IN THE TWELVE (12) MONTH PERIOD
                PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, OR HUNDRED GREAT BRITISH POUND
                (GBP£100), IF NO SUCH PAYMENTS HAVE BEEN MADE, AS APPLICABLE. THE LIMITATIONS OF
                DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
                TREPR AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT
                APPLY TO YOU.
              </p>

              <h5>28. INDEMNIFICATION</h5>

              <p>
                You agree to release, defend, indemnify, and hold Trepr and its affiliates and
                subsidiaries, and their officers, directors, employees and agents, harmless from and
                against any claims, liabilities, damages, losses, and expenses, including, without
                limitation, reasonable legal and accounting fees, arising out of or in any way
                connected with (a) your access to or use of the Site, Application, Services, or
                Collective Content or your violation of these Terms; (b) your User Content; (c) your
                (i) interaction with any User, (ii) request of any Travel services, or (iii)
                creation of a Listing; (d) the use, condition or consumption of a Travel service by
                you, including but not limited to any death, injuries, losses, or damages
                (compensatory, direct, incidental, consequential or otherwise) of any kind arising
                in connection with or as a result of a use of a Travel service; and (e) your
                participation in the Referral Program.
              </p>

              <h5>29. REPORTING MISCONDUCT</h5>

              <p>
                If you obtain Travel service from anyone who you feel is acting or has acted
                inappropriately, including but not limited to anyone who (i) engages in offensive,
                violent or sexually inappropriate behavior, (ii) you suspect of stealing or over
                charging you, or (iii) engages in any other disturbing conduct, you should
                immediately report such person to the appropriate authorities and then to Trepr by
                contacting us with your police station or transportation government authority and
                report number; provided that your report will not obligate us to take any action
                beyond that required by law (if any) or cause us to incur any liability to you.
              </p>

              <p>
                In the case of people service, if either User or Passenger misguides or deviates
                from travel plan as not provided in the Travel service, then please report it to
                respective authorities immediately and then bring it to our notice so that we will
                arrange potential alternatives for a safe trip.
              </p>

              <p>
                In the case of package service, If Traveler doesn't deliver the package at all or
                the same way it was handed over at origin for example some items are missing or
                tampered, please take a picture of items and bring it to our notice immediately so
                that we will work with Traveler to identify the reasons and reimburse only if it is
                fault of Traveler.
              </p>

              <p>
                In the case of product service, If Traveler couldn't complete a product request in a
                stipulated time or completes only partially, please bring it to our notice
                immediately so that we will arrange another potential Traveler or seek alternatives
                for successful completion of task and Seekers may be charged only for completed
                product services.
              </p>

              <h5>30. ENTIRE AGREEMENT</h5>

              <p>
                These Terms constitute the entire and exclusive understanding and agreement between
                Trepr and you regarding the Site, Application, Services, Collective Content, and any
                requests or Listings of Travel services made via the Site, Application and Services,
                and these Terms supersede and replace any and all prior oral or written
                understandings or agreements between Trepr and you regarding requests or listings of
                Travel services, the Site, Application, Services, and Collective Content.
              </p>

              <h5>31. ASSIGNMENT</h5>

              <p>
                You may not assign or transfer these Terms, by operation of law or otherwise,
                without Trepr's prior written consent. Any attempt by you to assign or transfer
                these Terms, without such consent, will be null and of no effect. Trepr may assign
                or transfer these Terms, at its sole discretion, without restriction. Subject to the
                foregoing, these Terms will bind and inure to the benefit of the parties, their
                successors and permitted assigns.
              </p>

              <h5>32. NOTICES</h5>

              <p>
                Any notices or other communications permitted or required hereunder, including those
                regarding modifications to these Terms, will be in writing and given by Trepr (i)
                via email (in each case to the address that you provide) or (ii) by posting to the
                Site or via the Application. For notices made by e-mail, the date of receipt will be
                deemed the date on which such notice is transmitted.
              </p>

              <h5>33. CONTROLLING LAW AND JURISDICTION</h5>

              <p>
                These Terms and your use of the Services will be interpreted in accordance with the
                laws of the Northern Ireland and the United Kingdom, without regard to its
                conflict-of-law provisions. You and we agree to submit to the personal jurisdiction
                of a court located in Northern Ireland or a United kingdom District Court for any
                actions for which the parties retain the right to seek injunctive or other equitable
                relief in a court of competent jurisdiction to prevent the actual or threatened
                infringement, misappropriation or violation of a party's copyrights, trademarks,
                trade secrets, patents, or other intellectual property rights, as set forth in the
                Dispute Resolution provision below.
              </p>

              <h5>34. DISPUTE RESOLUTION</h5>

              <p>
                You and Trepr agree that any dispute, claim or controversy arising out of or
                relating to these Terms or the breach, termination, enforcement, interpretation or
                validity thereof, or to the use of the Services or use of the Site or Application
                (collectively, "Disputes") will be settled by binding arbitration, except that each
                party retains the right to seek injunctive or other equitable relief in a court of
                competent jurisdiction to prevent the actual or threatened infringement,
                misappropriation or violation of a party's copyrights, trademarks, trade secrets,
                patents, or other intellectual property rights. You acknowledge and agree that you
                and Trepr are each waiving the right to a trial by jury or to participate as a
                plaintiff or class user in any purported class action or representative proceeding.
                Further, unless both you and Trepr otherwise agree in writing, the arbitrator may
                not consolidate more than one person's claims, and may not otherwise preside over
                any form of any class or representative proceeding. If this specific paragraph is
                held unenforceable, then the entirety of this "Dispute Resolution" section will be
                deemed void. Except as provided in the preceding sentence, this "Dispute Resolution"
                section will survive any termination of these Terms.
              </p>

              <p>
                Arbitration Rules and Governing Law. The arbitration will be administered by The
                London Court of International Arbitration ("LCIA") in accordance with the Commercial
                Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes
                (the "LCIA Rules") then in effect, except as modified by this "Dispute Resolution"
                section. (The LCIA Rules are available at
                http://www.lcia.org/Dispute_Resolution_Services/lcia-arbitration-rules-2014.aspx or
                by calling the LCIA at +44 (0) 20 7936 6200.) The International Arbitration Act will
                govern the interpretation and enforcement of this section.
              </p>

              <p>
                Arbitration Process. A party who desires to initiate arbitration must provide the
                other party with a written Demand for Arbitration as specified in the LCIA Rules.
                The arbitrator will be either a retired judge or an attorney licensed to practice
                law in the country of Northern Ireland, UK and will be selected by the parties from
                the LCIA 's roster of consumer dispute arbitrators. If the parties are unable to
                agree upon an arbitrator within seven (7) days of delivery of the Demand for
                Arbitration, then the LCIA will appoint the arbitrator in accordance with the LCIA
                Rules.
              </p>

              <p>
                Arbitration Location and Procedure. Unless you and Trepr otherwise agree, the
                arbitration will be conducted in the county where you reside. If your claim does not
                exceed £500, then the arbitration will be conducted solely on the basis of documents
                you and Trepr submit to the arbitrator, unless you request a hearing or the
                arbitrator determines that a hearing is necessary. If your claim exceeds £500, your
                right to a hearing will be determined by the LCIA Rules. Subject to the LCIA Rules,
                the arbitrator will have the discretion to direct a reasonable exchange of
                information by the parties, consistent with the expedited nature of the arbitration.
              </p>

              <p>
                Arbitrator's Decision. The arbitrator will render an award within the time frame
                specified in the LCIA Rules. The arbitrator's decision will include the essential
                findings and conclusions upon which the arbitrator based the award. Judgment on the
                arbitration award may be entered in any court having jurisdiction thereof. The
                arbitrator's award damages must be consistent with the terms of the "Limitation of
                Liability" section above as to the types and the amounts of damages for which a
                party may be held liable. The arbitrator may award declaratory or injunctive relief
                only in favor of the claimant and only to the extent necessary to provide relief
                warranted by the claimant's individual claim. If you prevail in arbitration you will
                be entitled to an award of attorney's fees and expenses, to the extent provided
                under applicable law. Trepr will not seek, and hereby waives all rights it may have
                under applicable law to recover, attorneys' fees and expenses if it prevails in
                arbitration.
              </p>

              <p>
                Fees. Your responsibility to pay any LCIA filing, administrative and arbitrator fees
                will be solely as set forth in the LCIA Rules. However, if your claim for damages
                does not exceed £5,000, Trepr will pay all such fees unless the arbitrator finds
                that either the substance of your claim or the relief sought in your Demand for
                Arbitration was frivolous or was brought for an improper purpose (as measured by the
                standards set forth in LICA).
              </p>

              <p>
                Changes. Notwithstanding the provisions of the "Modification" section above, if
                Trepr changes this "Dispute Resolution" section after the date you first accepted
                these Terms (or accepted any subsequent changes to these Terms), you may reject any
                such change by sending us written notice (including by email) within 30 days of the
                date such change became effective, as indicated in the "Last Updated Date" above or
                in the date of Trepr's email to you notifying you of such change. By rejecting any
                change, you are agreeing that you will arbitrate any Dispute between you and Trepr
                in accordance with the provisions of this "Dispute Resolution" section as of the
                date you first accepted these Terms (or accepted any subsequent changes to these
                Terms).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTerms',
  created() {
    document.title = 'Trepr - Terms And Conditions | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Know our terms and conditions on which  TREPR, LTD Offers you access to the Trepr platform.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Trepr Terms And Conditions,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
